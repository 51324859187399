import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

let browserAgent: BrowserAgent | null = null;

export function initNewRelicBrowser() {
  if (typeof window === 'undefined' || window?.appConfig?.remixApp !== 'production') {
    return null;
  }

  if (browserAgent) {
    return browserAgent;
  }

  try {
    const config = {
      init: { distributed_tracing: { enabled: true }, privacy: { cookies_enabled: true }, ajax: { deny_list: ['bam.nr-data.net'] }, jserrors: { enabled: true } },
      info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: window.appConfig.newRelicLicenseKey, applicationID: window.appConfig.newRelicApplicationId, sa: 1 },
      loader_config: { accountID: '4436692', trustKey: '3414783', agentID: window.appConfig.newRelicApplicationId, licenseKey: window.appConfig.newRelicLicenseKey, applicationID: window.appConfig.newRelicApplicationId },
      feature_flags: ['soft_nav']
    };

    browserAgent = new BrowserAgent(config);
    console.log('New Relic Browser initialized successfully');
    return browserAgent;
  } catch (error) {
    console.error('Failed to initialize New Relic Browser:', error);
    return null;
  }
}

export function getNewRelicBrowser() {
  return browserAgent || initNewRelicBrowser();
}

export function sendCustomError(error: Error, customAttributes?: Record<string, any>) {
  const agent = getNewRelicBrowser();
  if (agent) {
    agent.noticeError(error, customAttributes);
  }
}

export function addCustomAttribute(name: string, value: string | number | boolean) {
  const agent = getNewRelicBrowser();
  if (agent) {
    agent.setCustomAttribute(name, value);
  }
}
